import request from '@/utils/requestV2'
const qs = require('qs')

// 添加订单到合同中
export function addOrder (data) {
  return request({
    url: '/ooh-scp/v3/contractorder/addOrder',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 删除合同订单执行价数据
export function deleteOrder (data) {
  return request({
    url: '/ooh-scp/v3/contractorder/deleteorder',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取推荐付款时间点和金额
export function getExpectPayList (data) {
  return request({
    url: '/ooh-scp/v3/contractorder/getexpectpaylist',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取订单执行价
export function getOrderPrice (data) {
  return request({
    url: '/ooh-scp/v3/contractorder/getorderprice',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 配置订单执行价
export function setOrderPrice (data) {
  return request({
    url: '/ooh-scp/v3/contractorder/setorderprice',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 查看合同订单详情 (所有订单按产品汇总)
export function getOrderProductList (data) {
  return request({
    url: '/ooh-scp/v3/contractorder/getorderproductlist',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 查看合同订单详情(分类) (按订单=》档期=》产品)
export function getOrderScheduleCategoryList (data) {
  return request({
    url: '/ooh-scp/v3/contractorder/getorderschedulecategorylist',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取订单的结算价信息
export function getorderplanprice (data) {
  return request({
    url: '/ooh-scp/v3/contractorder/getorderplanprice',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取订单中的服务费明细
export function getOrderGoodServiceFee (data) {
  return request({
    url: '/ooh-scp/v3/contractorder/getOrderGoodServiceFee',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 保存订单的媒介使用费接口
export function saveOrderGoodUseFee (data) {
  return request({
    url: '/ooh-scp/v3/contractorder/saveOrderGoodUseFee',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 保存订单的服务费
export function saveGoodServiceFee (data) {
  return request({
    url: '/ooh-scp/v3/contractorder/saveGoodServiceFee',
    method: 'post',
    data: qs.stringify(data)
  })
}
