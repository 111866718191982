<template>
  <div>
    <Divider dashed><span class="divider-text">合同基本信息</span></Divider>
    <Row class="task-amount" style="text-align:left;">
      <i-col span="24">
        <h4 class="p-b-5">
         <label class="title m-r-5">合同编号</label><strong>{{ contract.code }}</strong>
        </h4>

        <h4 class="p-b-5">甲方信息</h4>
        <Row>
          <i-col span="8"><label class="title m-r-5">公司名称</label>{{ contract.customerInfoBean.name }}</i-col>
          <i-col span="8"
            ><label class="title m-r-5">公司地址</label>{{ contract.customerInfoBean.address }}</i-col
          >
          <i-col span="8"
            ><label class="title m-r-5">联系人</label>{{ contract.customerInfoBean.contact }}</i-col
          >
          <i-col span="8"
            ><label class="title m-r-5">联系人电话</label>{{ contract.customerInfoBean.tel }}</i-col
          >
          <i-col span="8"><label class="title m-r-5">开户银行</label>{{ contract.customerInfoBean.bank }}</i-col>
          <i-col span="8"
            ><label class="title m-r-5">银行账号</label>{{ contract.customerInfoBean.bankAccount }}</i-col
          >
          <i-col span="8"><label class="title m-r-5">公司税号</label>{{ contract.customerInfoBean.code }}</i-col>
        </Row>

        <h4 class="hrHead">订购概览</h4>
        <i-table stripe size="small"  :data="products" :columns="productColumns"
        :summary-method="handleSummary"
        show-summary></i-table>

        <h4 class="p-t-10 p-b-10">付款信息</h4>
        <div class="p-b-10">
          按照约定，甲方共需向乙方支付媒介使用费、服务费用共计
          <span class="money">
            {{ formatMoney(contract.amount) }}(
            {{ formatUpperMoney(contract.amount) }}) </span
          >。
          <span v-show="contract.paymentBeanList.length > 0"
            >其中，甲方必须</span
          >

          <div
            v-for="(payItem, i) in contract.paymentBeanList"
            :key="'paymentBean' + i"
          >
            {{ i + 1 }}. 在 {{ payItem.date }} 前支付
            <span class="money">
              {{ formatMoney(payItem.amount) }} ({{
                formatUpperMoney(payItem.amount)
              }}
              )
            </span>
            <span v-if="payItem.mark.length>0">，注：{{payItem.mark}}</span>
          </div>
        </div>
      </i-col>
    </Row>

    <div v-if="isAuth('settlementtask_operation')">
    <Divider dashed v-if="taskList.length"><span class="divider-text">开票申请</span></Divider>
    <Row
      v-for="(item, index) in taskList"
      :key="'task-' + index"
      style="margin-top: 20px"
    >
      <i-col span="24">
        <TaskItems :task="item" :id='id' @on-reloadData="reloadtask"></TaskItems>
      </i-col>
    </Row>
    </div>

    <Divider dashed><span class="divider-text">本合同开票记录</span></Divider>
    <Table stripe :data="historyList" size="small" :columns="tableColumns"></Table>
  </div>
</template>

<script>
import { parseUpperMoney } from '@/utils'
import { toMoney } from '@/utils/wnumb_own'
import { checkContractDetail } from '@/api/scp/contract'
import { getInvoiceApplicationsPage } from '@/api/scp/invoiceapplication'
import { getInvoiceList, invalidInvoice } from '@/api/scp/invoice'
import { getOrderProductList } from '@/api/scp/contractorder'
import { GetCurrentSchedule } from '@/utils/dateFormat'
import TaskItems from './TaskItems'

const MAX_CELL_ROW = 2
export default {
  components: {
    TaskItems
  },
  props: {
    id: Number
  },
  data () {
    return {
      contract: {
        paymentBeanList: [],
        customerInfoBean: []
      },

      products: [],
      productColumns: [
        { title: '资源名称', key: 'goodName' },
        {
          title: '采购数量',
          render: (h, data) => {
            const types = []
            data.row.scheduleQuantityList.forEach(item => {
              types.push(h('p', [
                h('span', GetCurrentSchedule(item.startDate, item.endDate)),
                h('span', { style: { color: '#ee7c4b' } }, '(' + (item.normalSignQuantity + item.recommendSignQuantity) + item.productUnit + ')'),
                item.recommendSignQuantity > 0 ? h('span', '(含特惠' + item.recommendSignQuantity + '' + item.productUnit + ')') : ''

              ]))
            })

            let showMore
            const tooMany = data.row.scheduleQuantityList.length - MAX_CELL_ROW > 1
            const simple = tooMany ? types.slice(0, MAX_CELL_ROW) : types.slice(0)

            if (tooMany) {
              showMore = h('a', {
                on: {
                  click: () => {
                    data.row.showAll = !data.row.showAll
                  }
                }
              }, [
                h('Icon', {
                  props: {
                    type: data.row.showAll ? 'ios-arrow-down' : 'ios-arrow-up',
                    color: '#ed4014',
                    size: 'large'
                  }

                }),
                h('span', { style: { color: '#ed4014' } }, data.row.showAll ? '显示更多' : '隐藏')

              ])
            }
            simple.push(showMore)
            types.push(showMore)
            return h('div', data.row.showAll ? simple : types)
          }
        },
        {
          title: '媒体刊例总价',
          key: 'usePrice',
          render: (h, data) => {
            return h('span', { style: { color: '#44bd32' } }, this.formatMoney(data.row.usePrice))
          }
        },
        {
          title: '媒体发布费',
          key: 'publishPirce',
          render: (h, data) => {
            let disCountText
            if (this.showDisCount) {
              disCountText = h(
                'span',
                { class: 'discount' },
                this.computeShowDiscount(data.row.usePrice, data.row.normalSellPrice + data.row.recommendSellPrice)
              )
            }
            let warning
            // 公益、二类商业都不显示这条消息。   其余的验证是否低于最低限价
            if (data.row.lowerSettlementPrice) {
              warning = h('p', {
                style: {
                  color: '#ed4014',
                  'font-size': 'small'
                }
              }, '低于最低折扣')
            }
            return h('div', [
              h('span', { style: { color: '#44bd32' } }, this.formatMoney(data.row.publishPirce)),
              disCountText,
              warning
            ])
          }
        },
        {
          title: '制作费',
          key: 'producePrice',
          render: (h, data) => {
            if (this.contract.notSetPrice) {
              return h('div', [
                h(
                  'span',
                  '-'
                )
              ])
            }

            let disCountText
            if (this.showDisCount) {
              disCountText = h(
                'span',
                { class: 'discount' },
                this.computeShowDiscount(data.row.publishProducePrice, data.row.producePrice)
              )
            }

            return h('div', [
              h(
                'span', { class: 'money' },
                this.formatMoney(data.row.producePrice)
              ),
              disCountText
            ])
          }
        },
        {
          title: '上下刊费',
          key: 'installPrice',
          render: (h, data) => {
            if (this.contract.notSetPrice) {
              return h('div', [
                h(
                  'span',
                  '-'
                )
              ])
            }

            let disCountText
            if (this.showDisCount) {
              disCountText = h(
                'span',
                { class: 'discount' },
                this.computeShowDiscount(data.row.publishInstallPrice, data.row.installPrice)
              )
            }

            return h('div', [
              h(
                'span', { class: 'money' },
                this.formatMoney(data.row.installPrice)
              ),
              disCountText
            ])
          }
        },
        {
          title: '小计',
          key: 'subtotalPrice',
          render: (h, data) => {
            return h('span', {
              style: {
                color: '#44bd32'
              }
            }, this.formatMoney(data.row.subtotalPrice))
          }
        }

      ],

      taskTypes: [],
      historyList: [],
      tableColumns: [
        {
          title: '发票号码',
          key: 'code'
        },
        {
          title: '开票金额(含税)',
          key: 'amount',
          render: (h, params) => {
            return h('div', [
              h(
                'strong',
                {
                  style: { color: '#44bd32' }
                },
                toMoney(params.row.amount)
              )
            ])
          }
        },
        { title: '开票日期', key: 'date' },
        {
          title: '更新时间',
          key: 'applyUserName',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                params.row.status === -1
                  ? params.row.invalidTime
                  : params.row.createTime
              )
            ])
          }
        },
        {
          title: '发票类型',
          key: 'typeName'
        },
        {
          title: '费用类型',
          key: 'feeTypeName'
        },
        // {
        //   title: '开票人',
        //   key: 'createUserName'
        // },
        {
          title: '状态',
          key: 'statusName',
          render: (h, params) => {
            const color = this.formaStautsColor(params.row.status)
            return h('div', [
              h(
                'Tag',
                {
                  props: {
                    color: color
                  }
                },
                params.row.statusName
              )
            ])
          }
        },
        {
          title: '备注',
          key: 'statusName',
          render: (h, params) => {
            return h('div', [
              params.row.status === -1
                ? h(
                  'span',
                  params.row.invalidMark ? params.row.invalidMark : '-'
                )
                : h('span', params.row.reason ? params.row.reason : '-')
            ])
          }
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              params.row.status === 1
                ? h(
                  'a',
                  {
                    style: { marginRight: '5px', color: '#ef4f4f' },
                    on: {
                      click: () => {
                        this.invalid(params.row.id)
                      }
                    }
                  },
                  '取消'
                )
                : '-'
            ])
          }
        }
      ],
      currentTypeId: 1,

      taskList: []
    }
  },
  created () {
    this.getDetail()
    this.getTasks()
    this.initHistoryData()
  },
  methods: {
    formaStautsColor (status) {
      let color = ''
      if (status === -1) {
        // 无效
        color = '#3B3B3B'
      } else if (status === 1) {
        // 有效
        color = '#44bd32'
      }

      return color
    },
    getDetail () {
      checkContractDetail({ contractId: this.id }).then((res) => {
        this.contract = res
        getOrderProductList({ contractId: this.id, version: this.contract.versionId }).then(orderProducts => {
          this.products = []
          let lowerSettlementPrice = false
          let flag = false
          orderProducts.forEach(element => {
            // 计算各项汇总值
            element.publishPirce = element.normalSellPrice + element.recommendSellPrice
            element.servicePrice = element.installPrice + element.producePrice
            element.otherPrice = element.monitorPrice + element.industryProtectSignPrice + element.fixProtectSignPrice + element.breakScheduleSignPrice
            element.subtotalPrice = element.publishPirce + element.servicePrice + element.otherPrice

            // 公益、二类商业都不显示这条消息。   其余的验证是否低于最低限价
            lowerSettlementPrice = this.showDisCount && (element.normalSellPrice + element.recommendSellPrice) < element.settlementPrice && this.contract.contractCategory !== 2
            if (lowerSettlementPrice) { flag = true }

            this.products.push(Object.assign({}, element, { lowerSettlementPrice: lowerSettlementPrice, showAll: true }))
          })
          this.$emit('on-lowerSettlementPrice', flag)
        })
      })
    },
    handleSummary ({ columns, data }) {
      const needSummarykey = ['usePrice', 'publishPirce', 'producePrice', 'installPrice', 'otherPrice', 'subtotalPrice']
      const sums = {}
      columns.forEach((column, index) => {
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计'
          }
          return
        }
        if (needSummarykey.includes(key)) {
          const values = data.map((item) => Number(item[key]))
          if (!values.every((value) => isNaN(value))) {
            const v = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[key] = {
              key,
              value: toMoney(v)
            }
          } else {
            sums[key] = {
              key,
              value: '-'
            }
          }
        } else {
          sums[key] = {
            key,
            value: '-'
          }
        }
      })

      return sums
    },

    getTasks () {
      const query = {
        pageNumber: 1,
        pageSize: 100,
        bid: this.id,
        statusList: JSON.stringify([0, 1])
      }
      getInvoiceApplicationsPage(query).then((res) => {
        this.taskList = res.list
      })
    },
    formatMoney (number) {
      return toMoney(number)
    },
    formatUpperMoney (number) {
      return parseUpperMoney(number)
    },
    formatContractStautsColor (status) {
      let color = 'Gray'
      if (status === 0) {
        // 草稿
        color = 'default'
      } else if (status === 3) {
        // 创建合同中
        color = 'SeaGreen'
      } else if (status === 4) {
        // 待审批
        color = 'Orange'
      } else if (status === 5) {
        // 已通过
        color = 'SeaGreen'
      } else if (status === 6) {
        // 已拒绝
        color = '#ed4014'
      } else if (status === 9) {
        // 已归档
        color = 'Black'
      }

      return color
    },
    // 刷新当前的所有任务
    reloadtask () {
      this.getTasks()
      this.initHistoryData()
      this.$emit('on-reloadRight', this.id)
    },
    initHistoryData () {
      getInvoiceList({ relateId: this.id }).then((res) => {
        this.historyList = res
      })
    },
    // 无效开票
    invalid (id) {
      let desc = ''
      this.$Modal.confirm({
        title: '取消开票',
        render: (h) => {
          return h('Input', {
            attrs: {
              placeholder: '请输入取消原因'
            },
            props: {
              value: desc
            },
            on: {
              'on-change': (event) => {
                desc = event.target.value
              }
            }
          })
        },
        onOk: () => {
          invalidInvoice({ invoiceId: id, invalidMark: desc }).then((res) => {
            if (res && !res.errcode) {
              this.$Notice.success({ desc: '取消成功' })
              this.reloadtask()
            }
          })
        }
      })
    },
    computeShowDiscount (denominator, member) {
      if (denominator > 0) {
        const discount = (member / denominator) * 10

        // toFixed()方法, 四舍五入
        const showDisCount = discount.toFixed(2)
        // Math.floor()，不四舍五入 ，向下取整
        // const showDisCount = Math.floor(discount * 100) / 100

        return '(' + showDisCount + '折)'
      } else {
        return ''
      }
    }
  }
}
</script>
